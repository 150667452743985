import React from 'react'
import { getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import classNames from 'classnames'
import { BioButton } from '../shared'

export const LunchTeaser = ({
  leftItem,
  previewItem,
  contentItem,
  leftButton,
  headlineTextShadow,
  subTitleUp,
  currentWeek,
  weekStart,
  lunchWeekEnd,
  nextWeek,
  nextWeekOfferDate,
  isBetweenOfferWeeks,
  postTeaserPreview,
  mobile
}) => {
  const subTitleElem = (
    <p className="normal-case text-sm lg:text-base headline-text-shadow-bold">
      {currentWeek && !isBetweenOfferWeeks
        ? contentItem?.subline || contentItem?.teaserContent?.subtitle
        : leftItem && `${postTeaserPreview.subline} ${nextWeekOfferDate}`}
      {previewItem && ` ${nextWeekOfferDate}`}
    </p>
  )
  const offerDate = `${weekStart} - ${lunchWeekEnd}`

  return (
    <div className="block w-full lg:w-1/2 flex-auto h-80 lg:h-100 relative mb-16 lg:mb-0 mr-4 lg:mr-8">
      <BgImage
        Tag="div"
        image={
          mobile && contentItem.customImageMobile
            ? getImage(contentItem.customImageMobile?.asset)
            : getImage(contentItem.customImage?.image?.asset)
        }
        alt={contentItem.customImage?.image?.alt}
        className="bm-rounded-box flex justify-center items-center w-full h-full px-8">
        <div className="text-center uppercase mt-10 text-white lg:w-full">
          {subTitleUp && subTitleElem}
          <h2
            className={classNames(
              'text-sm lg:text-xl',
              headlineTextShadow && 'headline-text-shadow-bold'
            )}>
            {currentWeek && !isBetweenOfferWeeks
              ? leftItem &&
                `${contentItem.title || contentItem?.teaserContent?.title}
                ${offerDate}`
              : postTeaserPreview?.title}
          </h2>
          {!subTitleUp && subTitleElem}
          {currentWeek && !isBetweenOfferWeeks ? (
            leftItem && (
              <div className="pt-20 lg:pt-12">
                <a href={`${currentWeek?.file1?.asset?.url}?dl`}>
                  <BioButton button={leftButton} />
                </a>
              </div>
            )
          ) : (
            <div className="pt-8 lg:pt-12">
              <a href={`${nextWeek?.file1?.asset?.url}?dl`}>
                <BioButton button={leftButton} />
              </a>
            </div>
          )}
        </div>
      </BgImage>
    </div>
  )
}
